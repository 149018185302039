<template>
  <nav class="navigation-body">
    <div class="hamburger-menu" ref="hamburgerMenu">
      <div class="hamburger"></div>
      <div class="hamburger"></div>
      <div class="hamburger"></div>
    </div>

    <ul class="nav-links" ref="navLinks">
      <li v-for="item in navigation_data" :key="item.id">
        <a class="p-medium" @click="goToElement(item.link)">{{ item.text }}</a>
      </li>
    </ul>
  </nav>
</template>

<script setup>
  import { navigation_data } from '@/db';
  import { onMounted, ref } from 'vue';
  import smoothscroll from 'smoothscroll-polyfill';

  smoothscroll.polyfill();

  const hamburgerMenu = ref("");
  const navLinks = ref("");

  function goToElement(link) {
    let element = document.getElementById(link);
    
    element.scrollIntoView({behavior: 'smooth'});
    if (navLinks.value.classList.contains('show-links')) {
      navLinks.value.classList.remove('show-links');
    }
  }

  onMounted(() => {

    hamburgerMenu.value.addEventListener('click', () => {
      navLinks.value.classList.add('show-links');
    })

 
  })
</script>

<style lang="scss">
  .hamburger-menu {
    display: none;
  }
  .nav-links {
    display: flex;
    align-items: center;
    gap: 20px;

    text-decoration: none;
    list-style: none;
  }

  @media (max-width: 735px) {
    .navigation-body {
      display: flex;
      flex-direction: column;
      align-items: center;

      .nav-links {
        display: none;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        flex-direction: column;
        align-items: center;
        justify-content: center;

        background-color: #624EB6;
        margin: 0;
        padding: 0;
        box-sizing: border-box;

        li {
          padding: 30px;
          width: 100%;
        }
      }

      .show-links {
        display: flex;
      }

      .hamburger-menu {
        width: 3rem;
        height: 3rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        background: #7D64E7;
        border-radius: 50%;

        .hamburger {
          width: 1.5rem;
          height: 3px;
          background: white;
          margin: 2px;
          border-radius: 2px;
        }
      }
    }
  }
  
</style>