<template>
  <button @click="goToQuiz">{{ btnText }}</button>
</template>

<script setup>
  import { defineEmits, defineProps } from 'vue';

  defineProps({
    btnText: String,
  });

  defineEmits(['action',]);

  function goToQuiz() {
    window.open('https://brainy2.w2a.dkostage.com');
  }
</script>