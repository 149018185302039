<template>
  <nav id="footer-nav-main">
    <ul>
      <li v-for="item in navigation_data" :key="item.id">
        <a class="p-medium" @click="goToElement(item.link)">{{ item.text }}</a>
      </li>
    </ul>
  </nav>
</template>

<script setup>
  import { navigation_data } from '@/db';

  function goToElement(link) {
    let element = document.getElementById(link);
    
    element.scrollIntoView({behavior: 'smooth'});
  }
</script>