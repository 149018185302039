<template>
  <header>
    <HeaderApp />
  </header>

  <main>
    <MainApp />
  </main>

  <footer>
    <FooterApp />
  </footer>
</template>

<script setup>
import FooterApp from './components/FooterApp.vue';
import HeaderApp from './components/HeaderApp.vue';
import MainApp from './components/MainApp.vue';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;

  scroll-behavior: smooth;
}
</style>
