import motivates_icon_1 from '@/assets/for_main/for_motivates/motivates-icon-1.svg';
import motivates_icon_2 from '@/assets/for_main/for_motivates/motivates-icon-2.svg';
import motivates_icon_3 from '@/assets/for_main/for_motivates/motivates-icon-3.svg';

import benefits_icon_1 from '@/assets/for_main/for_benefits/Rocket.svg';
import benefits_icon_2 from '@/assets/for_main/for_benefits/Dashboard Gauge 1.svg';
import benefits_icon_3 from '@/assets/for_main/for_benefits/Calculator 1.svg';
import benefits_icon_4 from '@/assets/for_main/for_benefits/Computer Chip 2.svg';
import benefits_icon_5 from '@/assets/for_main/for_benefits/Target 3.svg';
import benefits_icon_6 from '@/assets/for_main/for_benefits/Paint Palette.svg';

import awaits_img_1 from '@/assets/for_main/for_awaits/awaits-11.png';
import awaits_img_2 from '@/assets/for_main/for_awaits/awaits-22.png';
import awaits_img_3 from '@/assets/for_main/for_awaits/awaits-33.png';

import mission_img_1 from '@/assets/for_main/mission-result-1.svg';
import mission_img_2 from '@/assets/for_main/mission-result-2.png';

import who_train_img_1 from '@/assets/for_main/for_benefits/who-training-1.svg';
import who_train_img_2 from '@/assets/for_main/for_benefits/who-training-2.svg';
import who_train_img_3 from '@/assets/for_main/for_benefits/who-training-3.svg';

import how_train_icon_1 from '@/assets/for_main/how-train-1.svg';
import how_train_icon_2 from '@/assets/for_main/how-train-2.svg';
import how_train_icon_3 from '@/assets/for_main/how-train-3.svg';
import how_train_icon_4 from '@/assets/for_main/how-train-4.svg';

import feedback_img_1 from '@/assets/for_main/feedback-1.png';
import feedback_img_2 from '@/assets/for_main/feedback-2.png';
import feedback_img_3 from '@/assets/for_main/feedback-3.png';

export const navigation_data = [
  {
    id: 1,
    link: 'train-motivates-main',
    text: 'About us',
  },  
  {
    id: 2,
    link: 'statistics-main',
    text: 'Statistics',
  },
  {
    id: 3,
    link: 'main-mission-main',
    text: 'Our mission',
  },
  {
    id: 4,
    link: 'how-to-train-main',
    text: 'How to train',
  },
  {
    id: 5,
    link: 'users-review-main',
    text: 'Review',
  },
]

export const motivates_list_data = [
  {
    id: 1,
    icon: motivates_icon_1,
    h: 'Individual training plan for your brain',
    p: 'Customized brain workouts targeting specific areas',
    style: 'motivates-skin-b',//#FFBDB5
  },
  {
    id: 2,
    icon: motivates_icon_2,
    h: '25+ engaging games focused on logic puzzles & riddles',
    p: 'Improve your skills by playing fun mini games',
    style: 'motivates-purple-b',//#CEB7FF
  },
  {
    id: 3,
    icon: motivates_icon_3,
    h: 'Progress and rank statistics',
    p: 'Review progress, assess rankings for a comprehensive overview',
    style: 'motivates-blue-b',//#9DE6FF
  }
]

export const noticable_list_data = [
  {
    id: 1,
    h: '17',
    pref: '+',
    postf: '%',
    p: 'to reaction speed with just a week of training',
    style: 'big-blue',
  },
  {
    id: 2,
    h: '1.6',
    pref: 'x',
    postf: '',
    p: 'to concentration in 1 month',
    style: 'big-red',
  },
  {
    id: 3,
    h: '2.1',
    pref: 'x',
    postf: '',
    p: 'enhance attention to detail in 2 months',
    style: 'big-violet',
  },
  {
    id: 4,
    h: '19',
    pref: '+',
    postf: '%',
    p: 'to memorization in 1 month of training',
    style: 'big-orange'
  },
]
export const benefits_list_data = [
  {
    id: 1,
    icon: benefits_icon_1,
    h: 'Cognitive speed',
    p: 'Improve your reaction time, efficiency and productivity',
    style: 'pink-b',//#FFD4D0
  },
  {
    id: 2,
    icon: benefits_icon_2,
    h: 'Visual processing speed',
    p: 'Boost your speed of processing information',
    style: 'brown-b',//#FFEDCC
  },
  {
    id: 3,
    icon: benefits_icon_3,
    h: 'Accurate calculation',
    p: 'Perform calculations more quickly and accurately',
    style: 'brown-b',//#FFEDCC
  },
  {
    id: 4,
    icon: benefits_icon_4,
    h: 'Memory training',
    p: 'Improve memory retention',
    style: 'yellow-b',//#FFFBAA
  },
  {
    id: 5,
    icon: benefits_icon_5,
    h: 'Focus',
    p: 'Focus on one object and ignore nosy thoughts',
    style: 'purplelight-b',//#EFE3FF
  },
  {
    id: 6,
    icon: benefits_icon_6,
    h: 'Creativity',
    p: 'Use your imagination to generate solutions',
    style: 'pinklight-b',//#FFE2E2
  }
]

export const statistics_data = [
  {
    id: 1,
    color: 'fill-blue', //#48C1F8
    border: 'fill-blue-b',
    h: 'Сoncentration',
  },
  {
    id: 2,
    color: 'fill-red', //#FF5151
    border: 'fill-red-b',
    h: 'Memory',
  },
  {
    id: 3,
    color: 'fill-yellow', //#F9B300
    border: 'fill-yellow-b',
    h: 'Attention to detail',
  },
  {
    id: 4,
    color: 'fill-brown', //#EF6E26
    border: 'fill-brown-b',
    h: 'Focus on objects',
  },
  {
    id: 5,
    color: 'fill-purple', //#B56CE4
    border: 'fill-purple-b',
    h: 'Reaction speed',
  },
  {
    id: 6,
    color: 'fill-green', //#4DB601
    border: 'fill-green-b',
    h: 'Logical thinking',
  },
  {
    id: 7,
    color: 'fill-pink', //#FF85D0
    border: 'fill-pink-b',
    h: 'Mental arithmetic',
  },
]

export const train_users_data = [
  {
    id: 1,
    h: '5',
    pref: '',
    postf: 'minutes',
    p: 'on average spend studying every day',
    color: 'big-red', //#FF6433
    border: 'skin-b', //#FFCEBF
  },
  {
    id: 2,
    h: '67',
    pref: '',
    postf: '%',
    p: "there's a training session every day",
    color: 'big-yellow',//#FFC107
    border: 'yellow-b',//#FFE598
  },
  {
    id: 3,
    h: '42',
    pref: '',
    postf: '%',
    p: 'are moving to a new level of cognitive abilities',
    color: 'big-green',//#66CF00
    border: 'green-b',//#B9F282
  },
]

export const what_awaits_data = [
  {
    id: 1,
    img: awaits_img_1,
    h: 'Individual training plan for your brain',
    p: 'Customized brain workouts targeting specific areas',
    style: 'pink-b', //#E4C8FF
  },
  {
    id: 2,
    img: awaits_img_2,
    h: '25+ exciting games & riddles',
    p: 'Available for all the users',
    style: 'purple-b',//#C4AFFF
  },
  {
    id: 3,
    img: awaits_img_3,
    h: 'Progress and rank statistics',
    p: 'Track your skills and collect awards',
    style: 'blue-b',//#A6C2FF
  },
]

export const mission_result_data = [
  {
    id: 1,
    img: mission_img_1,
    h: 'Main mission',
    p: "We're developing exciting educational platform accessible to every student worldwide with a mobile device. Our global team strives to make education enjoyable, engaging, and effective for anyone who wants to study, wherever they are. ",
  },
  {
    id: 2,
    img: mission_img_2,
    h: 'Result guarantee',
    p: "Game trainings are based on the methods of neuropsychologists and experts in the field of human cognitive abilities, which have proven their effectiveness in the course of many experiments and scientific works.",
  },
]

export const who_train_data = [
  {
    id: 1,
    img: who_train_img_1,
    h: 'Younger age',
    p: {
      item1: 'Improving the learning process in educational institutions',
      item2: 'Prevention of attention deficit disorder (ADD)',
    }
  },
  {
    id: 2,
    img: who_train_img_2,
    h: 'Middle age',
    p: {
      item1: 'Increase personal productivity and efficiency',
      item2: 'Make fewer mistakes and omissions at work',
    }
  },
  {
    id: 3,
    img: who_train_img_3,
    h: 'Elderly age',
    p: {
      item1: 'Keep your mind sharp and clear longer',
      item2: 'Prevention diseases caused by decreased intellectual load',
    }
  },
]

export const how_train_data = [
  {
    id: 1,
    icon: how_train_icon_1,
    h: 'Assess your abilities',
    p: 'Improve your reaction time, efficiency and productivity',
    style: 'bluedark-b',//#CDDDFF
  }, 
  {
    id: 2,
    icon: how_train_icon_3,
    h: 'Track your progress',
    p: 'Stay up to date with your current level',
    style: 'skin-b',//#FFBDB5
  },
  {
    id: 3,
    icon: how_train_icon_2,
    h: 'Do a daily workout & play games',
    p: 'Play mini-games to improve your abilities',
    style: 'sea-b',//#B5F8FF
  },
  {
    id: 4,
    icon: how_train_icon_4,
    h: 'Earn points and increase ranks',
    p: 'Increase your gaming level by earning points',
    style: 'yellow-b',//#FFF0A3
  },
]

export const feedback_data = [
  {
    id: 1,
    avatar: feedback_img_1,
    name: 'Olivia Anderson',
    text: 'Brainy Train is my go-to for mental workouts! The diverse puzzles keep me hooked, and the gradual difficulty increase ensures a challenging yet rewarding experience. A must-try for anyone looking to boost brainpower on the go!',
  },
  {
    id: 2,
    avatar: feedback_img_2,
    name: 'Benjamin Mitchell',
    text: "Impressed by Brainy Train's seamless blend of entertainment and brain training. The intuitive interface and captivating challenges make it enjoyable. I've noticed a positive impact on my memory and problem-solving skills.",
  },
  {
    id: 3,
    avatar: feedback_img_3,
    name: 'Emma Taylor',
    text: 'Brainy Train is a gem! It turned my gaming time into a productive brain boost. The puzzles are both entertaining and mentally stimulating. The graphics are vibrant, and the daily challenges keep me coming back for more.',
  },
]
